<template>
  <div class="container">
    <div class="row">
        <div class="col-12 col-lg-3">
          <div class="card">
            <div class="card-header">
              الاجهزة المضافة
            </div>
            <div class="card-body">
              <h4>
                {{ stats.all }}
              </h4>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-3">
          <div class="card">
            <div class="card-header">
              الاجهزة المتصة
            </div>
            <div class="card-body">
              <h4>
                {{ stats.online }}
              </h4>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-3" v-for="(value, id) in stats.servers" :key="id">
          <div class="card">
            <div class="card-header">
              سيرفر #{{ id }}
            </div>
            <div class="card-body">
              <h4>
                {{ value }}
              </h4>
            </div>
          </div>
        </div>
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                <div class="row">
                    <div class="col-12 col-lg-4 g">
                        <span>البحث</span>
                        <input type="text" placeholder="ابحث هنا..." class="form-control" v-model="q" @keyup="page = 0; gett();">
                    </div>
                </div>
                <img :src="require('@/assets/images/loading.svg')" alt="" width="50" v-if="loading">
                    <div class="col-12 table-responsive">
                        <table class="table table-hover table-bordered table-sm">
                            <thead>
                              <th>
                                  العميل
                              </th>
                              <th>
                                  العنوان
                              </th>
                              <th>
                                  العمليات المعلقة
                              </th>
                              <th>
                                  الرقم التسلسلي
                              </th>
                              <th>
                                  الموديل
                              </th>
                              <th>
                                  نوع الجهاز
                              </th>
                              <th>
                                  الحالة
                              </th>
                              <th>
                                  المستخدمين
                              </th>
                              <th>
                                  البصمات
                              </th>
                              <th>
                                  بصمات الوجه
                              </th>
                              <th>
                                  السجلات
                              </th>
                              <th>
                                  IP
                              </th>
                              <th>
                                  خيارات
                              </th>
                          </thead>
                          <tbody>
                              <tr v-for="device in devices" :key="device._id">
                                  <td>
                                      <span v-if="device.user">
                                        <span v-if="device.user._id">
                                            <a target="_blank" :href="'https://s.tahdir.net/autologin/' + device.user.jwt">
                                            <i class="fa fa-external-link"></i>
                                            {{device.user.name}} </a>
                                        </span>
                                      </span>
                                  </td>
                                  <td>
                                      {{ device.title }}
                                  </td>
                                  <td>
                                      <span v-if="device.recordsx == 0">0</span>
                                      <span v-if="device.recordsx > 0" class="text-danger">
                                          <img :src="require('@/assets/images/loading.svg')" style="width: 16px;" alt="">
                                          {{ device.recordsx }}
                                          <u href="#" @click="deleteOrders(device.serial_number)">الغاء</u>
                                          </span>
                                  </td>
                                  <td>
                                      {{ device.serial_number }}
                                  </td>
                                  <td>
                                      {{ device.device_name }}
                                  </td>
                                  <td>
                                      {{ device.type == 'students' ? 'للطلاب' : 'للمعلمين' }}
                                  </td>
                                  <td v-html="device.last_update">
                                      
                                  </td>
                                  <td>
                                      {{ device.users }}
                                  </td>
                                  <td>
                                      {{ device.fingerprints }}
                                  </td>
                                  <td>
                                      {{ device.faces }}
                                  </td>
                                  <td>
                                      {{ device.records }}
                                  </td>
                                  <td>
                                      {{ device.ip }}
                                  </td>
                                  <td>
                                      <button class="btn btn-sm btn-primary" v-b-modal.options @click="current = device">
                                          <i class="fa fa-cogs"></i>
                                          خيارات
                                      </button>
                                  </td>
                              </tr>
                          </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="options" title="خيارات" hide-footer size="sm">
        <div class="row">
            <div class="col-12 g">
                <button class="btn btn-primary btn-block btn-default" v-b-modal.upload-reports>
                    <i class="fa fa-upload"></i>
                    رفع تقارير من الجهاز
                </button>
            </div>
            <div class="col-12 g">
                <button class="btn btn-primary btn-block btn-success " v-b-modal.logs @click="getlogs()">
                    <i class="fa fa-clock-o"></i>
                    السجلات المرفوعة حديثاً
                </button>
            </div>
            <div class="col-12 g">
                <button class="btn btn-primary btn-block btn-primary " @click="syncUpload()" style="background: #596FB7 !important; border: none">
                    <i class="fa fa-upload"></i>
                    مزامنة البيانات رفع
                </button>
            </div>
            <div class="col-12 g">
                <button class="btn btn-primary btn-block btn-info " @click="syncDownload()" style="background: #AC87C5 !important; border: none">
                    <i class="fa fa-download"></i>
                    مزامنة البيانات تنزيل
                </button>
            </div>
            <div class="col-12 g">
                <button class="btn btn-block btn-outline-danger " @click="cmd(current._id)">
                    <i class="fa fa-code"></i>
                    CMD
                </button>
            </div>
            <div class="col-12 g">
                <button class="btn btn-primary btn-block btn-danger " @click="deleteDevice(current._id)">
                    <i class="fa fa-trash"></i>
                    حذف الجهاز
                </button>
            </div>
        </div>
    </b-modal>
    <b-modal id="upload-reports" title="خيارات" hide-footer size="sm">
        <h4>
            رفع تقرير يوم معين
        </h4>
        <div class="form-group">
          <label for="">التاريخ</label>
          <input type="date"
            class="form-control" v-model="from">
        </div>
        <div class="col-12 text-center">
            <button class="btn btn-primary" @click="uploadSingle()">
                <i class="fa fa-upload"></i>
                رفع التقرير
            </button>
        </div>
        <hr>
        <h4>
            رفع تقرير فترة
        </h4>
        <div class="form-group">
          <label for="">من</label>
          <input type="date"
            class="form-control" v-model="from">
        </div>
        <div class="form-group">
          <label for="">الى</label>
          <input type="date"
            class="form-control" v-model="to">
        </div>
        <div class="col-12 text-center">
            <button class="btn btn-primary" @click="uploadBetween()">
                <i class="fa fa-upload"></i>
                رفع التقرير
            </button>
        </div>
    </b-modal>
    <b-modal id="logs" title="خيارات" hide-footer size="lg">
        <div class="col-12 table-responsive">
            <table class="table table-hover table-bordered table-sm">
                <thead>
                    <th>
                        الهوية
                    </th>
                    <th>
                        التوقيت
                    </th>
                    <th>
                        الجهاز
                    </th>
                </thead>
                <tbody>
                    <tr v-for="log in logs" :key="log.time + log.number">
                        <td>
                            {{ log.number }}
                        </td>
                        <td>
                            {{ log.time }}
                        </td>
                        <td>
                            {{ log.serial_number }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </b-modal>
  </div>
</template>

<script>
import {  VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
    BModal
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  data(){
    return {
      jwt: localStorage.getItem("jwt"),
      devices: [],
      q: window.location.hash ? window.location.hash.replace("#", "") : null,
      current: {},
      from: new Date().toISOString().split("T")[0],
      to: new Date().toISOString().split("T")[0],
      first: false,
      stats: {
        servers: {}
      }
    }
  },
  created(){
    var g = this;
    g.gett()
  },
  methods: {
    getStats(){
      var g = this;
      g.stats.all = g.devices.length;
      g.stats.online = 0;
      g.devices.forEach(function(a){
        if(a.last_update.includes('متصل الان')){
          g.stats.online++;
        }
        if(!g.stats.servers[a.server]){
          g.stats.servers[a.server] = 0;
        }
        g.stats.servers[a.server] = g.stats.servers[a.server] + 1;
      })
    },
    gett(){
      var g = this;
      $.post(api + '/admin/devices/list', {
        jwt: this.jwt,
        q: this.q
      }).then(function(a){
        g.devices = a.response
        if(!g.first){
          g.getStats()
        }
        g.first = true;
      })
    },
    cmd(id){
      var g = this;
      var t = prompt("CMD", "");
      if(t && t != ""){
        $.post(api + '/admin/devices/cmd', {
            jwt: this.jwt,
            id: id,
            cmd: t
        }).then(function(a){
            g.gett()
            alert("تم اعطاء الامر " + t, 100)
        })
      }
    },
    getDevices(){
      this.gett();
    },
    deleteDevice(id){
        if(confirm("متاكد من حذف الجهاز نهائياً؟")){
            var g = this;
            $.post(api + '/user/devices/delete', {
                jwt: this.current.user.jwt,
                id: id
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response, r.status)
                }else{
                    g.getDevices()
                    $("#options___BV_modal_header_ > button").click()
                }
            }).fail(function(){
                alert("حدث خطأ", 200)
            })
        }
        },
        getlogs(){
            var g = this;
            $.post(api + '/user/devices/logs', {
                jwt: this.current.user.jwt,
                sn: this.current.serial_number
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response, r.status)
                }else{
                    g.logs = [];
                    r.response.forEach(function(a){
                        (JSON.parse(a.data)).forEach(function(b){
                            g.logs.push({
                                number: b.id,
                                time: b.time,
                                serial_number: a.serial_number
                            })
                        })
                    })
                }
            }).fail(function(){
                alert("حدث خطأ", 200)
            })
        },
        uploadSingle(){
            var g = this;
            $.post(api + '/user/devices/upload', {
                jwt: this.current.user.jwt,
                from: this.from + ' 01:00:00',
                to: this.from + ' 23:59:59',
                sn: this.current.serial_number
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response, r.status)
                }else{
                    $("#upload-reports___BV_modal_header_ > button").click()
                    g.getDevices()
                    alert("تم ارسال العملية بنجاح", 100)
                }
            }).fail(function(){
                alert("حدث خطأ", 200)
            })
        },
        uploadBetween(){
            var g = this;
            $.post(api + '/user/devices/upload', {
                jwt: this.current.user.jwt,
                from: this.from + ' 01:00:00',
                to: this.to + ' 23:59:59',
                sn: this.current.serial_number
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response, r.status)
                }else{
                    $("#upload-reports___BV_modal_header_ > button").click()
                    g.getDevices()
                    alert("تم ارسال العملية بنجاح", 100)
                }
            }).fail(function(){
                alert("حدث خطأ", 200)
            })
        },
        syncUpload(){
            var g = this;
            $.post(api + '/user/devices/sync-upload', {
                jwt: this.current.user.jwt,
                sn: this.current.serial_number
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response, r.status)
                }else{
                    g.getDevices()
                    alert("تم ارسال العملية بنجاح", 100)
                }
            }).fail(function(){
                alert("حدث خطأ", 200)
            })
        },
        syncDownload(){
            var g = this;
            $.post(api + '/user/devices/sync-download', {
                jwt: this.current.user.jwt,
                sn: this.current.serial_number
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response, r.status)
                }else{
                    g.getDevices()
                    alert("تم ارسال العملية بنجاح", 100)
                }
            }).fail(function(){
                alert("حدث خطأ", 200)
            })
        },
        deleteOrders(sn){
            if(confirm("متاكد من الغاء الاوامر المعلقة لهذا الجهاز؟")){
                var g = this;
                $.post(api + '/user/devices/cancel-orders', {
                    jwt: this.current.user.jwt,
                    sn: sn
                }).then(function(r){
                    g.loading = false
                    if(r.status != 100){
                        alert(r.response, r.status)
                    }else{
                        g.getDevices()
                        alert("تم الالغاء بنجاح", 100)
                    }
                }).fail(function(){
                    alert("حدث خطأ", 200)
                })
            }
        }
  }
}
</script>
